import React from "react";
import "daisyui";

const SubCards = ({ categories, tags, onCategoryClick, onTagClick, selectedCategory, selectedTag }) => {
  return (
    <div className="space-y-4 w-72">
      <div className="card bg-white rounded-2xl p-4">
        <h2 className="text-lg font-bold mb-2 flex items-center">
          <div className="w-1 h-5 bg-violet-500 mr-2"></div>
          Categories
        </h2>
        {categories.map((category, index) => (
          <button
            key={index}
            onClick={() => onCategoryClick(category.name)}
            className={`flex justify-between mb-1 text-gray-700 hover:text-violet-700 w-full ${
              selectedCategory === category.name ? 'text-violet-700 font-bold' : ''
            }`}
          >
            <span>{category.name}</span>
            <span className="bg-violet-100 text-violet-500 rounded-md px-2 py-1 text-sm hover:bg-violet-200">
              {category.count}
            </span>
          </button>
        ))}
      </div>

      <div className="card bg-white rounded-2xl p-4">
        <h2 className="text-lg font-bold mb-2 flex items-center">
          <div className="w-1 h-5 bg-violet-500 mr-2"></div>
          Tags
        </h2>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <button
              key={index}
              onClick={() => onTagClick(tag)}
              className={`bg-violet-100 text-violet-500 rounded-md px-2 py-1 text-sm hover:bg-violet-200 hover:text-violet-700 ${
                selectedTag === tag ? 'bg-violet-200 text-violet-700 font-bold' : ''
              }`}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubCards;