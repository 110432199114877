import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PostCard from './PostCard';
import Pagination from './Pagination';

const PageHolder = ({ postsData, onPostSelect }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;

  const totalPages = Math.ceil(postsData.length / postsPerPage);

  const currentPosts = postsData.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="flex flex-col space-y-4">
      {currentPosts.map((post) => (
        <motion.div
          key={post.id}
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.5, ease: 'easeOut' }}
        >
          <PostCard post={post} onSelect={onPostSelect} />
        </motion.div>
      ))}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default PageHolder;