import React, { useState, useEffect, useRef } from "react";
import ProfileCard from "./components/ProfileCard";
import SubCards from "./components/SubCards";
import PageHolder from "./components/PageHolder";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import data from './components/data.json';
import BlogView from './components/BlogView';

function App() {
  const { categoriesData, tagsData, profileData, postsData } = data;
  const [filteredPosts, setFilteredPosts] = useState(postsData);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const topRef = useRef(null);

  useEffect(() => {
    let newFilteredPosts = postsData;

    if (selectedCategory) {
      newFilteredPosts = newFilteredPosts.filter(post => post.category === selectedCategory);
    }

    if (selectedTag) {
      newFilteredPosts = newFilteredPosts.filter(post => post.tags.includes(selectedTag));
    }

    setFilteredPosts(newFilteredPosts);
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [selectedCategory, selectedTag, postsData, selectedPost]);

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName === selectedCategory ? null : categoryName);
    setSelectedTag(null);
    setSelectedPost(null);
  };

  const handleTagClick = (tagName) => {
    setSelectedTag(tagName === selectedTag ? null : tagName);
    setSelectedCategory(null);
    setSelectedPost(null);
  };

  const handlePostSelect = (postId) => {
    const post = postsData.find(p => p.id === postId);
    setSelectedPost(post);
  };

  const handleBack = () => {
    setSelectedPost(null);
  };

  return (
    <div className="flex flex-col min-h-screen bg-base-100">
      <div ref={topRef} />
      <div className="flex flex-col items-center w-full max-w-7xl mx-auto">
        <Navbar />
        <div className="flex flex-col lg:flex-row w-full space-y-4 lg:space-y-0 lg:space-x-4 p-4">
          {/* Sidebar */}
          <div className="w-full lg:w-1/4 flex flex-col space-y-4 items-center lg:items-start order-2 lg:order-1">
            <ProfileCard
              img={profileData.img}
              name={profileData.name}
              description={profileData.description}
              iconUrls={profileData.iconUrls}
            />
            <SubCards 
              categories={categoriesData} 
              tags={tagsData} 
              onCategoryClick={handleCategoryClick}
              onTagClick={handleTagClick}
              selectedCategory={selectedCategory}
              selectedTag={selectedTag}
            />
          </div>

          <div className="w-full lg:w-3/4 flex flex-col space-y-4 order-1 lg:order-2">
            {selectedPost ? (
              <BlogView post={selectedPost} onBack={handleBack} />
            ) : (
              <PageHolder postsData={filteredPosts} onPostSelect={handlePostSelect} />
            )}
          </div>
        </div>
      <Footer />
      </div>
    </div>
  );
}

export default App;