import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'; 

const BlogView = ({ post, onBack}) => {
  const { title, date, wordCount, readingTime, md } = post;
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    const importMarkdown = async () => {
      const markdownContent = await fetch(md).then((res) => res.text());
      setMarkdown(markdownContent);
    };

    importMarkdown();
  }, [md]);

  return (
    <div className="bg-violet-50 p-6 rounded-lg">
         <div className="flex items-center mb-4">
        <button onClick={onBack} className="w-8 h-8 bg-violet-100 text-violet-500 rounded-md flex items-center justify-center hover:bg-violet-200 transition-colors">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
          </svg>
        </button>
        <span className="ml-2 text-violet-500">Back</span>
      </div>
      <div className="flex items-center space-x-2 text-sm text-gray-500 mb-4">
        <span>{date}</span>
        <span>•</span>
        <span>{wordCount} words</span>
        <span>•</span>
        <span>{readingTime}</span>
      </div>
      <h1 className="text-4xl font-bold mb-2">{title}</h1>
      <div className="divider"></div>
      <ReactMarkdown remarkPlugins={[gfm]} className="markdown-content">
        {markdown}
      </ReactMarkdown>
    </div>
  );
};

export default BlogView;
