import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white w-full rounded-t-2xl px-6 py-6 flex justify-between items-center mx-auto">
      <span>
        Project Book © {new Date().getFullYear()}
      </span>
    </footer>
  );
};

export default Footer;