import React from "react";

const ProfileCard = ({ img, name, description, iconUrls }) => {
  return (
    <div className="card w-72 bg-white p-2 rounded-2xl">
      <figure className="px-2 pt-2">
        <img
          src={img}
          alt="Profile"
          className="rounded-2xl"
        />
      </figure>
      <div className="card-body items-center text-center p-2">
        <h2 className="card-title text-lg font-bold mb-1">{name}</h2>
        <p className="text-gray-500 mb-2 text-sm">{description}</p>
        <div className="card-actions flex space-x-1">
          {iconUrls.map((icon, index) => (
            <a
              key={index}
              href={icon.url}
              className="w-8 h-8 bg-violet-100 text-violet-500 rounded-md flex items-center justify-center hover:bg-violet-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={`${icon.iconClass} text-xl`}></i>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;