import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-white w-full rounded-b-2xl px-6 py-6 flex justify-between items-center mx-auto">
      <a href="/" className="text-violet-500 font-medium text-xl flex items-center">
        <i className="fas fa-book mr-3 text-2xl"></i>
        Project Book
      </a>
      <a href="https://jeromegeorge.pages.dev/" className="text-gray-800">
        <i className="fas fa-user-circle text-2xl"></i>
      </a>
    </nav>
  );
};

export default Navbar;