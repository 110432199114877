import React from 'react';

const PostCard = ({ post, onSelect }) => {
  const { id, title, date, description, wordCount, readingTime } = post;

  const handleArrowClick = () => {
    onSelect(id);
  };

  return (
    <div className="card p-4 rounded-2xl bg-gray-50 w-full max-w-4xl ">
      <div className="flex items-center justify-between h-full p-4">
        <div>
          <h2 className="card-title text-2xl font-bold text-black">{title}</h2> 
          <div className="flex items-center mt-2">
            <div className="mr-2">
              <i className="fas fa-calendar-alt w-5 h-5 text-primary"></i>
            </div>
            <p className="text-sm">{date}</p>
          </div>
          <p className="mt-2 text-sm text-gray-600">{description}</p>
          <div className="mt-2 text-sm text-gray-400">
            {wordCount} words | {readingTime}
          </div>
        </div>
        <div className="flex items-center h-full">
          <button onClick={handleArrowClick} className="w-8 h-8 bg-violet-100 text-violet-500 rounded-md flex items-center justify-center hover:bg-violet-200 transition-colors">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostCard;